'use client';

import { ENDPOINT } from '@/lib/utils';
import { ProgressBar } from '@/widgets';
import axios from 'axios';
import { LazyMotion, domAnimation } from 'framer-motion';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { PropsWithChildren } from 'react';
import MantineProvider from './Mantine';
import ScriptsProvider from './Scripts';
import StylesProvider from './Styles';
import { SWRProvider } from './Swr';

type Props = PropsWithChildren & { locale: string; messages: AbstractIntlMessages };

axios.defaults.baseURL = ENDPOINT;

export function Providers({ children, locale, messages }: Props) {
  return (
    <NextIntlClientProvider locale={locale} messages={messages} timeZone="Asia/Tashkent">
      <SWRProvider>
        <MantineProvider>
          <StylesProvider>
            <ProgressBar />
            <ScriptsProvider>
              <LazyMotion strict features={domAnimation}>
                {children}
              </LazyMotion>
            </ScriptsProvider>
          </StylesProvider>
        </MantineProvider>
      </SWRProvider>
    </NextIntlClientProvider>
  );
}
