import { routing } from '@/i18n/routing';
import { AUTHORITY_CODE, ENDPOINT } from '@/lib/utils';
import { createQueryString } from '@/lib/utils/helpers';
import { useLocale } from 'next-intl';
import { PropsWithChildren } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';

type QueryParams = Record<string, string | null | undefined | false>;

export type FetchOptions = {
  headers?: HeadersInit;
  params?: QueryParams;
};

export async function fetcher<T>(path: string, locale = routing.defaultLocale, options: FetchOptions = {}): Promise<T> {
  const { headers, params } = options;

  const url = path.startsWith('/') ? `${ENDPOINT}${path}` : path;
  const queryParams = createQueryString(params);

  delete options?.params;

  try {
    const response = await fetch(`${url}${queryParams ? `?${queryParams}` : ''}`, {
      ...options,
      headers: {
        language: locale,
        code: AUTHORITY_CODE,
        ...headers,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText} URL: ${path}`);
    }

    const data: T = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch Data Error:', error, path);
    throw error;
  }
}

export function SWRProvider({ children }: PropsWithChildren) {
  const locale = useLocale();
  const options: SWRConfiguration = {
    provider: () => new Map(),
    fetcher: ([resource, options], init) => fetcher(resource, locale, { ...options, ...init }),
    revalidateOnFocus: false,
  };

  return <SWRConfig value={options}>{children}</SWRConfig>;
}
