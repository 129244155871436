import dynamic from 'next/dynamic';
import { PropsWithChildren } from 'react';
const Scripts = dynamic(() => import('@/components/global').then((mod) => mod.Scripts), {
  ssr: false,
});

export default function ScriptsProvider({ children }: PropsWithChildren) {
  return (
    <>
      {children}
      <Scripts />
    </>
  );
}
