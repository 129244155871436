'use client';

import { montserrat } from '@/fonts';
import { cyrillicLocale, DAYJS_LOCALES, karakalpakLocale, NAVBAR_Z_INDEX } from '@/lib/utils';
import {
  ColorSchemeScript,
  colorsTuple,
  Container,
  createTheme,
  DefaultMantineColor,
  MantineColorsTuple,
  MantineProvider as Provider,
} from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { PropsWithChildren } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/uz-latn';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useLocale } from 'next-intl';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale(cyrillicLocale);
dayjs.locale(karakalpakLocale);

export const CONTAINER_CLASSNAME = 'flex flex-col py-10 3xl:py-6';

const BREAKPOINTS = {
  '4xl': '1600px',
  '3xl': '1400px',
  '2xl': '1200px',
  xl: '992px',
  lg: '768px',
  md: '600px',
  sm: '520px',
  xsm: '420px',
};

const COMPONENTS = {
  Container: Container.extend({
    defaultProps: {
      size: '100rem',
      className: CONTAINER_CLASSNAME,
    },
  }),
};

const theme = createTheme({
  activeClassName: '',
  primaryColor: 'primary',
  fontFamily: montserrat.style.fontFamily,
  cursorType: 'pointer',
  colors: {
    primary: colorsTuple('#013d8c'),
    secondary: colorsTuple('#707070'),
  },
  breakpoints: BREAKPOINTS,
  components: COMPONENTS,
});

type ExtendedCustomColors = 'primary' | 'secondary' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

export default function MantineProvider({ children }: PropsWithChildren) {
  const locale = useLocale();

  return (
    <>
      <ColorSchemeScript forceColorScheme="light" />
      <Provider theme={theme} forceColorScheme="light">
        <DatesProvider settings={{ locale: DAYJS_LOCALES[locale] || 'uz-latn' }}>{children}</DatesProvider>
        <Notifications
          position="top-center"
          zIndex={NAVBAR_Z_INDEX + 2}
          containerWidth={800}
          limit={3}
          withinPortal={false}
        />
      </Provider>
    </>
  );
}
