import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/var/www/reserv/argos/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/reserv/argos/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/reserv/argos/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/var/www/reserv/argos/node_modules/next/font/local/target.css?{\"path\":\"src/shared/assets/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Montserrat-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./Montserrat-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./Montserrat-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./Montserrat-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./Montserrat-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Activities"] */ "/var/www/reserv/argos/src/components/authority/activities/Activities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Banners"] */ "/var/www/reserv/argos/src/components/authority/banners/Banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/var/www/reserv/argos/src/components/authority/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GovLinks"] */ "/var/www/reserv/argos/src/components/authority/gov-links/GovLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/reserv/argos/src/components/authority/header/HeaderView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InnerMarquee"] */ "/var/www/reserv/argos/src/components/authority/inner-marquee/InnerMarquee.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Map"] */ "/var/www/reserv/argos/src/components/authority/map/Map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Marquee"] */ "/var/www/reserv/argos/src/components/authority/marquee/Marquee.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsSection"] */ "/var/www/reserv/argos/src/components/authority/news-section/NewsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Projects"] */ "/var/www/reserv/argos/src/components/authority/projects/Projects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServiceEventLink"] */ "/var/www/reserv/argos/src/components/authority/service-event-link/ServiceEventLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialMediaLinks"] */ "/var/www/reserv/argos/src/components/authority/social-media-links/SocialMediaLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VacDocPoll"] */ "/var/www/reserv/argos/src/components/authority/vac-doc-poll/VacDocPoll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Documents"] */ "/var/www/reserv/argos/src/components/global/documents/Documents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/var/www/reserv/argos/src/components/global/error-boundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExternalInfo"] */ "/var/www/reserv/argos/src/components/global/external-info/ExternalInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackForm"] */ "/var/www/reserv/argos/src/components/global/feedback-form/FeedbackForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsContent"] */ "/var/www/reserv/argos/src/components/global/news/NewsContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsForm"] */ "/var/www/reserv/argos/src/components/global/news/NewsForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsList"] */ "/var/www/reserv/argos/src/components/global/news/NewsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsView"] */ "/var/www/reserv/argos/src/components/global/news/NewsView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PagesView"] */ "/var/www/reserv/argos/src/components/global/pages-view/PagesView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Scripts"] */ "/var/www/reserv/argos/src/components/global/scripts/Scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/var/www/reserv/argos/src/components/global/search/Input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Search"] */ "/var/www/reserv/argos/src/components/global/search/Search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/var/www/reserv/argos/src/shared/providers/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Body"] */ "/var/www/reserv/argos/src/shared/ui/body/Body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumb"] */ "/var/www/reserv/argos/src/shared/ui/breadcrumb/Breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CalendarLink"] */ "/var/www/reserv/argos/src/shared/ui/calendar-link/CalendarLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomLink"] */ "/var/www/reserv/argos/src/shared/ui/custom-link/CustomLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DetailsLink"] */ "/var/www/reserv/argos/src/shared/ui/details-link/DetailsLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Empty"] */ "/var/www/reserv/argos/src/shared/ui/empty/Empty.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkBox"] */ "/var/www/reserv/argos/src/shared/ui/link-box/LinkBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Masonry"] */ "/var/www/reserv/argos/src/shared/ui/masonry/Masonry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotFound"] */ "/var/www/reserv/argos/src/shared/ui/not-found/NotFound.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollAnimation"] */ "/var/www/reserv/argos/src/shared/ui/scroll-animation/ScrollAnimation.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/reserv/argos/src/shared/ui/section/section.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["SliderArrow"] */ "/var/www/reserv/argos/src/shared/ui/slider-arrow/SliderArrow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialMediaIcon"] */ "/var/www/reserv/argos/src/shared/ui/social-media-icon/SocialMediaIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseLoader"] */ "/var/www/reserv/argos/src/shared/ui/suspense-loader/SuspenseLoader.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/reserv/argos/src/shared/ui/three-stack/three-stack.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/var/www/reserv/argos/src/shared/ui/title/Title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Views"] */ "/var/www/reserv/argos/src/shared/ui/views/Views.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ZoomImage"] */ "/var/www/reserv/argos/src/shared/ui/zoom-image/ZoomImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accessibility"] */ "/var/www/reserv/argos/src/widgets/accessibility/Accessibility.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackToTop"] */ "/var/www/reserv/argos/src/widgets/back-to-top/BackToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Captcha"] */ "/var/www/reserv/argos/src/widgets/captcha/Captcha.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/var/www/reserv/argos/src/widgets/content/Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Employee"] */ "/var/www/reserv/argos/src/widgets/employee/Employee.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfoContent"] */ "/var/www/reserv/argos/src/widgets/info-content/InfoContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcher"] */ "/var/www/reserv/argos/src/widgets/language-switcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Messenger"] */ "/var/www/reserv/argos/src/widgets/messenger/Messenger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsCard"] */ "/var/www/reserv/argos/src/widgets/news-card/NewsCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OnlineConsultant"] */ "/var/www/reserv/argos/src/widgets/online-consultant/OnlineConsultant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaginationWidget"] */ "/var/www/reserv/argos/src/widgets/pagination/Pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Poll"] */ "/var/www/reserv/argos/src/widgets/poll/Poll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/var/www/reserv/argos/src/widgets/progress-bar/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PushNotification"] */ "/var/www/reserv/argos/src/widgets/push-notification/PushNotification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RightMenu"] */ "/var/www/reserv/argos/src/widgets/right-menu/RightMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Share"] */ "/var/www/reserv/argos/src/widgets/share/Share.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkipToMainContent"] */ "/var/www/reserv/argos/src/widgets/skip-to-main-content/SkipToMainContent.tsx");
